import httpClient from './http-client';

const list = (userId: any): Promise<any> => {
    const params = {
        query: {
            userId
        }
    };

    return httpClient.invoke('accessKey', 'list', params);
};

const add = (userId: string): Promise<any> => {
    const params = {
        query: {
            userId
        }
    };

    return httpClient.invoke('accessKey', 'add', params)
};

const revoke = (userId: string, accessKeyId: string): Promise<any> => {
    const params = {
        query: {
            userId,
            accessKeyId
        }
    };

    return httpClient.invoke('accessKey', 'revoke', params)
};

export default {
    list,
    add,
    revoke
}
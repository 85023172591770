<template>
  <div class="button-container">
    <v-divider class="mt-5" v-if="!hideBar"></v-divider>
    <div class="button-bar">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonBar",

  props: {
    hideBar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.button-bar {
  display: flex;
  gap: 8px;
  margin: 16px 0px;
}
</style>
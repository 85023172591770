<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <span class="text-h5">Detalhe do usuário</span>
      </v-col>
    </v-row>

    <ButtonBar>
      <v-btn color="primary" outlined @click="goBack">Voltar</v-btn>
      <v-btn
        type="button"
        color="error"
        @click="remove"
        v-if="!isNew"
        v-auth="'USER.REMOVE'"
        :loading="removeButton.loading"
        >Excluir</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="update"
        v-if="!isNew"
        v-auth="'USER.UPDATE'"
        :loading="updateButton.loading"
        >Alterar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="add"
        v-if="isNew"
        v-auth="'USER.ADD'"
        :loading="addButton.loading"
        >Adicionar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="requestPasswordReset"
        v-if="!isNew"
        v-auth="'USER.REQUEST_PASSWORD_RESET'"
        :loading="requestPasswordButton.loading"
        >Redefinir senha</v-btn
      >
    </ButtonBar>

    <v-form ref="userForm" v-model="validForm">
      <v-row>
        <v-col cols="12" sm="8">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="card-title">Dados básicos</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        label="Nome"
                        maxlength="120"
                        v-model="user.name"
                        required
                        :rules="[$rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        type="email"
                        label="E-mail"
                        maxlength="100"
                        v-model="user.email"
                        required
                        :rules="[$rules.required, $rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        label="Celular"
                        maxlength="20"
                        v-mask="$masks.phone"
                        v-model="user.mobile"
                        required
                        :rules="[$rules.required]"
                        type="tel"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        :items="data.userStatus"
                        label="Status"
                        v-model="user.status"
                        required
                        :rules="[$rules.required]"
                        item-value="id"
                        item-text="name"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" v-if="isClient">
                      <v-autocomplete
                        auto-select-first
                        chips
                        deletable-chips
                        multiple
                        small-chips
                        label="Clientes"
                        v-model="data.selectedClients"
                        :items="data.clients"
                        item-value="id"
                        item-text="label"
                        no-data-text="Nenhum cliente foi encontrado"
                        required
                        :rules="[$rules.requiredArray]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-auth="'ACCESS-KEY.LIST'" v-if="!isNew">
            <v-col cols="12">
              <v-card>
                <v-card-title class="card-title"
                  >Chaves de acesso
                  <v-btn
                    fab
                    color="primary accent-2"
                    x-small
                    @click="addAccessKey"
                    v-auth="'ACCESS-KEY.ADD'"
                    :loading="addAccessKeyButton.loading"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn></v-card-title
                >
                <v-card-text>
                  <v-data-table
                    :headers="accessKeyList.headers"
                    :items="accessKeyList.data"
                    :items-per-page="25"
                    :disable-sort="true"
                    :loading="accessKeyList.loading"
                    no-data-text="Nenhuma chave de acesso cadastrada"
                    loading-text="Carregando dados..."
                    class="app-table"
                    hide-default-footer
                  >
                    <template v-slot:[`item.created_at`]="{ item }">
                      {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm") }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip
                        small
                        :color="
                          item.status
                            | enum(data.accessKeyStatus, 'id', 'class')
                        "
                        dark
                        >{{ item.status | enum(data.accessKeyStatus) }}</v-chip
                      >
                    </template>

                    <template v-slot:[`item.revoke`]="{ item }">
                      <v-btn
                        icon
                        small
                        color="error"
                        @click="revokeAccessKey(item.access_key_id)"
                        :loading="revokeAccessKeyButton.loading"
                        v-if="item.status !== 'R'"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-alert
                    class="mt-4"
                    dense
                    icon="mdi-lock"
                    text
                    type="warning"
                    v-if="accessKey.access_key_id"
                  >
                    Copie a senha a seguir pois ela não será exibida novamente.
                    <strong>{{ accessKey.secret_access_key }}</strong>
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="4">
          <v-row v-auth="'PROFILE.LIST'">
            <v-col cols="12">
              <v-card>
                <v-card-title class="card-title">Perfis</v-card-title>
                <v-card-text>
                  <v-list v-for="module in data.modules" :key="module.id">
                    <v-list-item-content>
                      <v-list-item-title
                        class="list-header"
                        v-text="module.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item
                      v-for="profile in data.profiles.filter(
                        (profile) => profile.module_id === module.id
                      )"
                      :key="profile.id"
                    >
                      <v-checkbox
                        hide-details
                        multiple
                        v-model="data.selectedProfiles"
                        :value="profile.id"
                        :label="profile.name"
                      />
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { eventHub } from "@/main";
import authApi from "@/common/api/auth.api";
import userApi from "@/common/api/user.api";
import clientApi from "@/common/api/client.api";
import profileApi from "@/common/api/profile.api";
import accessKeyApi from "@/common/api/access-key.api";
import userStatus from "@/common/data/user-status.data";
import accessKeyStatus from "@/common/data/access-key-status.data";
import moduleData from "@/common/data/module.data";
import ButtonBar from "@/components/layout/ButtonBar";
import toaster from "@/common/util/toaster";
import moment from "moment";

export default {
  name: "UserDetails",

  components: { ButtonBar },

  computed: {
    isClient() {
      return this.data.selectedProfiles.find((id) => {
        let profile = this.data.profiles.find((p) => p.id === id);

        return profile && profile.module_id !== "BACKOFFICE";
      });
    },

    selectedModules() {
      let modules = this.data.modules.filter((module) =>
        this.data.selectedProfiles.find((selectedProfile) => {
          let fullProfile = this.profiles.find(
            (profile) => profile.id === selectedProfile
          );

          if (fullProfile) {
            return module.id === fullProfile.module_id;
          }
        })
      );

      return modules.map((module) => module.id);
    },
  },

  data() {
    return {
      data: {
        userStatus: userStatus,
        accessKeyStatus: accessKeyStatus,
        modules: [],
        profiles: [],
        clients: [],
        selectedProfiles: [],
        selectedClients: [],
      },
      moment: moment,
      validForm: true,
      isNew: true,
      accessKey: {},
      user: {
        status: "A",
        profiles: [],
      },
      accessKeyList: {
        headers: [
          { text: "Chave", value: "access_key_id" },
          { text: "Data de criação", value: "created_at" },
          { text: "Status", value: "status", align: "center" },
          { value: "revoke", align: "center" },
        ],
        data: [],
        loading: false,
      },
      removeButton: {
        loading: false,
      },
      updateButton: {
        loading: false,
      },
      addButton: {
        loading: false,
      },
      requestPasswordButton: {
        loading: false,
      },
      addAccessKeyButton: {
        loading: false,
      },
      revokeAccessKeyButton: {
        loading: false,
      },
    };
  },

  created() {
    this.listClients();
    this.listProfiles();
    this.load(this.$route.params.id);
  },

  methods: {
    load(userId) {
      if (userId !== "novo") {
        this.isNew = false;

        userApi.get(userId).then((result) => {
          this.user = result.data;

          this.data.selectedProfiles = this.user.profiles.map(
            (item) => item.id
          );
          this.data.selectedClients = this.user.clients.map((item) => item.id);

          this.listAccessKeys();
        });
      }
    },

    listClients() {
      clientApi.list({ size: -1 }).then((result) => {
        this.data.clients = result.data.data;
      });
    },

    listProfiles() {
      profileApi.list().then((result) => {
        this.data.profiles = result.data.data;

        this.data.modules = moduleData.filter((module) =>
          this.data.profiles.find((profile) => module.id === profile.module_id)
        );
      });
    },

    listAccessKeys() {
      if (authApi.hasPermission("ACCESS-KEY.LIST")) {
        this.accessKeyList.loading = true;

        accessKeyApi
          .list(this.user.id)
          .then((result) => {
            this.accessKeyList.loading = false;
            this.accessKeyList.data = result.data;
          })
          .catch(() => (this.accessKeyList.loading = false));
      }
    },

    goBack() {
      this.$router.replace("/usuarios");
    },

    async add() {
      this.$refs.userForm.validate();

      if (await this.$root.$confirm(this.validForm, "user.add")) {
        let data = {
          name: this.user.name,
          email: this.user.email,
          mobile: this.user.mobile,
          status: this.user.status,
          modules: this.data.selectedModules,
          profiles: this.data.selectedProfiles,
          clients: this.data.selectedClients,
        };

        this.addButton.loading = true;

        userApi
          .add(data)
          .then(() => {
            this.addButton.loading = false;
            toaster.show("Usuário adicionado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.addButton.loading = false));
      }
    },

    async update() {
      this.$refs.userForm.validate();

      if (await this.$root.$confirm(this.validForm, "user.update")) {
        let data = {
          name: this.user.name,
          email: this.user.email,
          mobile: this.user.mobile,
          status: this.user.status,
          modules: this.data.selectedModules,
          profiles: this.data.selectedProfiles,
          clients: this.data.selectedClients,
        };

        this.updateButton.loading = true;

        userApi
          .update(this.user.id, data)
          .then(() => {
            this.updateButton.loading = false;

            eventHub.$emit("REFRESH_CLIENTS");
            toaster.show("Usuário alterado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.updateButton.loading = false));
      }
    },

    async remove() {
      if (await this.$root.$confirm(true, "user.remove")) {
        this.removeButton.loading = true;

        userApi
          .remove(this.user.id)
          .then(() => {
            this.removeButton.loading = false;

            toaster.show("Usuário excluído com sucesso!");
            this.goBack();
          })
          .catch(() => (this.removeButton.loading = false));
      }
    },

    async addAccessKey() {
      if (await this.$root.$confirm(true, "accessKey.add")) {
        this.addAccessKeyButton.loading = true;

        accessKeyApi
          .add(this.user.id)
          .then((result) => {
            this.addAccessKeyButton.loading = false;

            toaster.show("Chave de acesso criada com sucesso!");
            this.accessKey = result.data;
            this.listAccessKeys();
          })
          .catch(() => (this.addAccessKeyButton.loading = false));
      }
    },

    async revokeAccessKey(accessKeyId) {
      if (await this.$root.$confirm(true, "accessKey.revoke")) {
        this.revokeAccessKeyButton.loading = true;

        accessKeyApi
          .revoke(this.user.id, accessKeyId)
          .then(() => {
            this.revokeAccessKeyButton.loading = false;

            toaster.show("Chave de acesso revogada com sucesso!");
            this.accessKey = {};
            this.listAccessKeys();
          })
          .catch(() => (this.revokeAccessKeyButton.loading = false));
      }
    },

    async requestPasswordReset() {
      if (await this.$root.$confirm(null, "user.requestPasswordReset")) {
        this.requestPasswordButton.loading = true;

        userApi
          .requestPasswordReset(this.user.email)
          .then(() => {
            this.requestPasswordButton.loading = false;

            toaster.show("Solicitação enviada por e-mail!");
          })
          .catch(() => (this.requestPasswordButton.loading = false));
      }
    },
  },
};
</script>
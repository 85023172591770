const data = [
    {
        id: 'A',
        name: 'Ativo',
        class: 'green'
    },
    {
        id: 'U',
        name: 'Inativo',
        class: 'red'
    }
];

export default [...data];
const data = [
    {
        id: 'A',
        name: 'Ativo',
        class: 'green'
    },
    {
        id: 'R',
        name: 'Revogado',
        class: 'red'
    }
];

export default [...data];